import defineAbility from '@/common/access-control/ability';
import { writeOrganizationId, writeOrganizationUserRole } from '@/utils/localStorage';
import { isNullOrUndefined, requestDelete, requestGet, requestPost, requestPut } from 'utils';

export const ORGANIZATIONS_LIST_SUCCESS = '@@organizations/ORGANIZATIONS_LIST_SUCCESS';
export const ORGANIZATIONS_LIST_FAILURE = '@@organizations/ORGANIZATIONS_LIST_FAILURE';
export const CREATE_ORGANIZATION_SUCCESS = '@@organizations/CREATE_ORGANIZATION_SUCCESS';
export const CREATE_ORGANIZATION_FAILURE = '@@organizations/CREATE_ORGANIZATION_FAILURE';
export const RESET_ORGANIZATION_SUCCESS = '@@organizations/RESET_ORGANIZATION_SUCCESS';
export const GET_ORGANIZATION_SUCCESS = '@@organizations/GET_ORGANIZATION_SUCCESS';
export const GET_ORGANIZATION_FAILURE = '@@organizations/GET_ORGANIZATION_FAILURE';
export const DELETE_CURRENT_ORGA_USER_SUCCESS = '@@organizations/DELETE_CURRENT_ORGA_USER_SUCCESS';
export const DELETE_CURRENT_ORGA_USER_FAILURE = '@@organizations/DELETE_CURRENT_ORGA_USER_FAILURE';
export const SAVE_USER_ROLE_FOR_AN_ORGANIZATION = '@@organizations/ SAVE_USER_ROLE_FOR_AN_ORGANIZATION';


/**
 * Save organization and user informations
 * @param {*} organizationId Organization's id
 * @param {*} userRole User role for the organization
 * @param {*} dispatch Instance of dispatch
 * @param {*} ability 1bility context
 */
export function storeOrganizationInformations(organizationId, userRole, dispatch, ability) {
    // Save current organization id & role
    writeOrganizationId(organizationId);

    // Save current role for this organization
    if (!isNullOrUndefined(userRole)) {
        writeOrganizationUserRole(userRole);
        dispatch(saveUserRoleForAnOrganization(userRole));

        // Update abilities as role has changed
        defineAbility(userRole, ability);
    }

}

/**
 * Creates an organization
 */
export async function createOrganization(data) {
    return await requestPost('/api/vulcan/organization', data);
}

/**
 * Allows an editor to create an oragnization with a user having administrator or distributor role
 * @param {*} userOrganization Object who contains user and organization informations
 * @returns Request response
 */
export async function createOrganizationWithAUser(userOrganization) {
    return await requestPost('/api/vulcan/user/invite/new-organization', userOrganization);
}

/**
 * Get user's organization list
 * @param {*} userId User's id
 */
export async function getUserOrganizations(userId) {
    return await requestGet(`/api/vulcan/organization/${userId}/list`);
}

/**
 * Save user's organization in Redux store
 * @param {*} id User id
 */
export function saveUserOrganizations(userId) {
    return async dispatch => {
        try {
            const response = await getUserOrganizations(userId);
            dispatch({
                type: ORGANIZATIONS_LIST_SUCCESS,
                payload: response.data
            });
            return response.data;
        } catch (error) {
            dispatch({
                type: ORGANIZATIONS_LIST_FAILURE,
                payload: error
            });
        }
    };
}

/**
 * Get an organization
 * @param {*} organizationId Organization's Id
 */
export function getOrganizationbyId(organizationId) {
    return dispatch => {
        return requestGet(`/api/vulcan/organization/${organizationId}`)
            .then(response => {
                dispatch({
                    type: GET_ORGANIZATION_SUCCESS,
                    payload: response.data
                });
                return response.data;
            })
            .catch(error => {
                dispatch({
                    type: GET_ORGANIZATION_FAILURE,
                    payload: error
                });
            });
    };
}

/**
 * Get an organization
 * @param {*} organizationId Organization's Id
 */
export function resetCurrentOrganization() {
    return dispatch => {
        dispatch({
            type: RESET_ORGANIZATION_SUCCESS,
            payload: {}
        });
    };
}

/**
 * Get an organization
 * @param {*} organizationId Organization's tag
 */
export async function getOrganizationByTag(organizationTag) {
    return await requestGet(`/api/vulcan/organization/tag/${organizationTag}`);
}

/**
 * Get all organizations in Vulcan. Only use by a superAdmin
 * @param {*} first First element
 * @param {*} count Number of elements requested
 */
export async function getAllOrganizationsInVulcan(first, count) {
    return await requestGet(`/api/vulcan/organization/admin/list/${first}/${count}`);
}

/**
 * Update an organization
 * @param {*} updatedOrganization Updated organization
 */
export async function updateOrganization(updatedOrganization) {
    return await requestPut('/api/vulcan/organization', updatedOrganization);
}

/**
 * Deactivates an organization, deleted from interface, but still kept in database
 * @param {*} organizationId Organization's Id
  * @returns Request response
 */
export async function deactivateOrganization(organizationId) {
    return await requestDelete(`/api/vulcan/organization/${organizationId}`);
}

/**
 * Research an organization by its name
 * @param {*} organizationName Updated organization
 * @param {*} first First element
 * @param {*} count Number of elements requested
 */
export async function researchOrganizationByName(first, count, organizationName) {
    return await requestGet(`/api/vulcan/organization/search/${first}/${count}`, null, [organizationName], 'organizationQuery');
}

/**
 * Store the role of the user
 * @param {*} userRole Role of the user
 */
export function saveUserRoleForAnOrganization(userRole) {
    return dispatch => {
        dispatch({
            type: SAVE_USER_ROLE_FOR_AN_ORGANIZATION,
            payload: userRole
        });
    };
}