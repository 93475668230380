import React from 'react';
import PropTypes from 'prop-types';
import { Layout, Skeleton, Row, Col } from 'antd';
const { Header, Sider, Content } = Layout;

function LoadingFallback(props) {

    const { hasSider } = props;

    const HeaderLoading = () => (
        <Row className="edition-header-menu">
            {hasSider ?
                <Col xs={4} sm={9} lg={12} xl={16} className="header-title height-max align-items-center">
                    <Skeleton.Input className='text-ellipsis ant-breadcrumb' active style={{ width: 250 }} />
                </Col>
                :
                <Col xs={4} sm={9} lg={12} xl={16} className="header-title height-max align-items-center">
                    <Row style={{ marginTop: 30, marginLeft: 25 }}>
                        <Col style={{ display: 'flex', textAlign: 'center', width: 150, height: 150 }}>
                            <Skeleton.Avatar shape='square' active style={{ width: 100, height: 100, marginTop: 30 }} />
                        </Col>
                        <Col style={{ marginLeft: 25 }}>
                            <Skeleton.Input className='text-ellipsis ant-breadcrumb' active style={{ width: 250 }} />
                        </Col>
                    </Row>
                </Col>
            }
            <Col xs={20} sm={15} lg={12} xl={8} className="height-max">
                <Row justify='space-around' style={{ height: '100%' }}>

                    {Array.from(Array(6)).map((_, i) => (
                        <div key={i} style={{ display: 'flex', alignItems: 'center' }}>
                            <Skeleton.Avatar active size={40} style={{ display: 'flex' }} />
                        </div>
                    )
                    )}
                </Row>
            </Col>
        </Row>
    );

    const SideBarLoading = () => (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 10 }}>
            <div style={{ display: 'flex', alignItems: 'center', textAlign: 'center', width: 150, height: 150 }}>
                <Skeleton.Avatar active shape='square' style={{ width: 100, height: 100 }} />
            </div>

            {Array.from(Array(4)).map((_, i) => (
                <div key={i} style={{
                    display: 'flex', justifyContent: 'center', marginBottom: 30, alignItems: 'center', width: 80, height: 80
                }}>
                    <Skeleton.Avatar active size={80} />
                </div>
            )
            )}
        </div>
    );


    return (
        <>
            <Layout className="vulcan-layout">
                {hasSider &&
                    <Sider className="vulcan-dashboard-sider">
                        <SideBarLoading />
                    </Sider>
                }
                <Layout>
                    <Header className="vulcan-header">
                        <HeaderLoading />
                    </Header>
                    <Content className="vulcan-content">
                        {/* NEED A LOADING CONTENT ?? */}
                    </Content>
                </Layout>
            </Layout>

        </>
    );
}

LoadingFallback.propTypes = {
    hasSider: PropTypes.bool
};

export default LoadingFallback;