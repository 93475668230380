import { requestGet } from '@/utils';

export const GET_ALL_SENSOR_FROM_SIMULATOR_SUCCESS = '@@sensors/GET_ALL_SENSOR_FROM_SIMULATOR_SUCCESS';
export const GET_ALL_SENSOR_FROM_SIMULATOR_FAILURE = '@@sensors/GET_ALL_SENSOR_FROM_SIMULATOR_FAILURE';

/**
 * Retrieves all sensors from a simulator
 * @param {string} productId Simulator id
 */
export function getAllSensorsFromASimulator(productId) {
    return dispatch => {
        return requestGet(`/api/vulcan/sensor/${productId}/list`)
            .then(response => {
                dispatch({
                    type: GET_ALL_SENSOR_FROM_SIMULATOR_SUCCESS,
                    payload: response.data
                });
            })
            .catch(error => {
                dispatch({
                    type: GET_ALL_SENSOR_FROM_SIMULATOR_FAILURE,
                    payload: error
                });
            });
    };
}

/**
 * Get a sensor by using its tag
 * @param {string} sensorTag Sensor's tag
 * @returns Request response
 */
export async function getSensorByTag(sensorTag) {
    return await requestGet(`/api/vulcan/sensor/basic/tag${sensorTag}`);
}