import { BLOCK_NOTIFICATION, MESSAGES_LIST_FETCHED } from './NotificationsActions';

/**
 * Initial state
 */
const initialState = {
	list: [],
	length: 0
};

/**
 * Handles what to do with state for each action
 */
const handlers = {

	// Messages list successfully fetched
	[MESSAGES_LIST_FETCHED]: (prevState, payload) => {
		return Object.assign({}, prevState, { list: payload, length: payload.length });
	},

	// Block a notification
	[BLOCK_NOTIFICATION]: (prevState, payload) => {
		const newList = prevState.list.filter(n => n.id !== payload);
		return Object.assign({}, prevState, { list: newList, length: newList.length });
	}
};

/**
 * Notifications reducer
 */
export default function notificationsReducer(state = initialState, action) {
	let handler = handlers[action.type];
	if (!handler) return state;
	return { ...state, ...handler(state, action.payload) };
}