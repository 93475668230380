import React from 'react';
// import ReactDOM from 'react-dom/client';
import { render } from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import { store } from './store';
import LocaleProvider from './i18n/LocaleProvider';

import defineAbility from './common/access-control/ability';
import { AbilityContext } from './common/access-control/Can';

// import AuthProvider from './auth/AuthProvider';
import { ReactKeycloakProvider as KeycloakProvider } from '@react-keycloak/web';
import { providerProps as keycloakProviderProps } from 'auth/keycloak';
import { readOrganizationUserRole } from './utils/localStorage';

import 'antd/dist/antd.css';

// Import moment locale for antd range picker
import 'moment/locale/fr';
import './assets/stylesheets/style.less';

const ability = defineAbility(readOrganizationUserRole());

// const root = ReactDOM.createRoot(document.getElementById('root'));

// With react 18.0.0, Keycloak do infinite reload after login, take KeycloakProvider out of StrictMode(only use in development)
// TODO When react-keycloak/web will be fixed, take KeycloakProvider inside of StrictMode
render(
	<KeycloakProvider {...keycloakProviderProps}>
		<React.StrictMode>
			<ReduxProvider store={store}>
				<AbilityContext.Provider value={ability}>
					<LocaleProvider>
						<BrowserRouter>
							<App />
						</BrowserRouter>
					</LocaleProvider>
				</AbilityContext.Provider>
			</ReduxProvider>
		</React.StrictMode>
	</KeycloakProvider>,
	document.getElementById('root')
);
