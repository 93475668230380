import {
    ORGANIZATIONS_LIST_SUCCESS, ORGANIZATIONS_LIST_FAILURE,
    CREATE_ORGANIZATION_SUCCESS, CREATE_ORGANIZATION_FAILURE,
    GET_ORGANIZATION_SUCCESS, GET_ORGANIZATION_FAILURE,
    RESET_ORGANIZATION_SUCCESS,
    SAVE_USER_ROLE_FOR_AN_ORGANIZATION
}
    from './OrganizationsActions';

const initialState = {
    organizations: [],
    selectedOrganization: {},
    currentOrganizationRole: ''
};

const handlers = {

    // List of organization successfully fetched
    [ORGANIZATIONS_LIST_SUCCESS]: (previousState, payload) => {
        return Object.assign({}, previousState, {
            organizations: payload
        });
    },
    // List of organization unsuccessfully fetched
    [ORGANIZATIONS_LIST_FAILURE]: (previousState, payload) => {
        return Object.assign({}, previousState, {
            errorCode: payload
        });
    },

    // Organization successfully created
    [CREATE_ORGANIZATION_SUCCESS]: previousState => {
        return Object.assign({}, previousState);
    },
    // Organization unsuccessfully created
    [CREATE_ORGANIZATION_FAILURE]: (previousState, payload) => {
        return Object.assign({}, previousState, {
            errorCode: payload
        });
    },

    // Organization successfully fetched
    [GET_ORGANIZATION_SUCCESS]: (previousState, payload) => {
        return Object.assign({}, previousState, {
            selectedOrganization: payload
        });
    },
    // Organization unsuccessfully fetched
    [GET_ORGANIZATION_FAILURE]: (previousState, payload) => {
        return Object.assign({}, previousState, {
            errorCode: payload
        });
    },

    // Organization successfully updated
    [RESET_ORGANIZATION_SUCCESS]: (previousState, payload) => {
        return Object.assign({}, previousState, {
            selectedOrganization: payload
        });
    },

    // User role successfully stored
    [SAVE_USER_ROLE_FOR_AN_ORGANIZATION]: (previousState, payload) => {
        return Object.assign({}, previousState, {
            currentOrganizationRole: payload
        });
    }
};

export default function organizationReducer(state = initialState, action) {
    let handler = handlers[action.type];
    if (!handler) return state;
    return { ...state, ...handler(state, action.payload) };
}