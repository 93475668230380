import { isNullOrUndefined, requestGet, requestPost, requestPut, requestDelete } from '@/utils';

export const GET_PRODUCTS_ACTIVE_LIST_SUCCESS = '@@simulators/GET_PRODUCT_ACTIVE_LIST_SUCCESS';
export const GET_PRODUCTS_ACTIVE_LIST_FAILURE = '@@simulators/GET_PRODUCT_ACTIVE_LIST_FAILURE';
export const GET_PRODUCT_ACTIVE_MODULES_LIST_SUCCESS = '@@simulators/GET_PRODUCT_ACTIVE_MODULES_LIST_SUCCESS';
export const GET_PRODUCT_ACTIVE_MODULES_LIST_FAILURE = '@@simulators/GET_PRODUCT_ACTIVE_MODULES_LIST_FAILURE';

/**
 * Gets the product with its id and the traduction corresponding to the locale given in the Vulcan-Session
 * @param {*} productId Product id
 * @returns Request response
 */
export async function getProductById(productId) {
	return await requestGet(`/api/vulcan/product/${productId}`);
}

/**
 * Gets simulator with its default language
 * @param {*} id Simulator id
 * @returns Request response
 */
export async function getProductByIdWithDefaultLanguage(id) {
	return await requestGet(`/api/vulcan/product/${id}/default`);
}

/**
 * Gets the product with its tag
 * @param {*} productTag Product tag
 * @returns Request response
 */
export async function getProductByTag(productTag) {
	return await requestGet(`/api/vulcan/product/tag/${productTag}`);
}

/**
 * Gets a list of products with their tag
 * @param {*} productTag Products tag
 * @returns Request response
 */
export async function getProductListByTag(productsTag) {
	return await requestPost('/api/vulcan/product/list', productsTag);
}

/**
 * Get active simulators (with at least one active license) list for the current organization
 */
export function getActiveProductsList() {
	return async dispatch => {
		try {
			const response = await requestGet('/api/vulcan/product/active/list');
			dispatch({
				type: GET_PRODUCTS_ACTIVE_LIST_SUCCESS,
				payload: { list: response.data }
			});
		} catch (error) {
			dispatch({
				type: GET_PRODUCTS_ACTIVE_LIST_FAILURE,
				payload: { errorCode: error.code }
			});
		}
	};
}

/**
 * Get the modules of a given product filter or not. If first, count and isFilteredByExercise are not provides, we gets all modules
 * @param {*} productId Simulator ID
 * @param {*} first Index of the page first element
 * @param {*} count Number of elements requested
 * @param {*} isFilteredByExercise Need to filter by exercise (Use on Dashboard)
 */
export async function getProductModulesList(productId, isFilteredByExercise, first, count) {
	if (isNullOrUndefined(first) && isNullOrUndefined(count)) {
		return await requestGet(`/api/vulcan/module/${productId}/list/${isFilteredByExercise}`);
	} else {
		return await requestGet(`/api/vulcan/module/${productId}/list/${isFilteredByExercise}/${first}/${count}`);
	}
}

/**
 * Gets products and organization informations
 * @param {*} productTagsAndOrganizationIds Object with list of product tags and list of organization ids
 * @returns Request response
 */
export async function getProductsAndOrganizationInformations(productTagsAndOrganizationIds) {
	return await requestPost('/api/vulcan/product/organizations/informations', productTagsAndOrganizationIds);
}

/**
 * Gets product's default exercises
 * @param {*} productId Simulator ID
 * @param {*} first Index of the page first element
 * @param {*} count Number of elements requested
 * @returns Request response
 */
export async function getProductDefaultExercises(productId, first, count) {
	if (isNullOrUndefined(first) && isNullOrUndefined(count)) {
		return await requestGet(`/api/vulcan/product/${productId}/exercises`);
	} else {
		return await requestGet(`/api/vulcan/product/${productId}/exercises/${first}/${count}`);
	}
}

/**
 * Gets product's default learning paths
 * @param {*} productId Simulator ID
 * @param {*} first Index of the page first element
 * @param {*} count Number of elements requested
 * @returns Request response
 */
export async function getProductDefaultLearningPaths(productId, first, count) {
	if (isNullOrUndefined(first) && isNullOrUndefined(count)) {
		return await requestGet(`/api/vulcan/product/${productId}/learningpaths`);

	} else {
		return await requestGet(`/api/vulcan/product/${productId}/learningpaths/${first}/${count}`);
	}
}

/**
 * Gets translation for a learning path corresponding to a locale
 * @param {string} simulatorId Learning path id
 * @param {string} locale The locale choosen to get the translation
 * @returns Request response
 */
export async function getSimulatorRessourcesTranslation(simulatorId, locale) {
	if (isNullOrUndefined(locale)) return await requestGet(`/api/vulcan/product/translation/${simulatorId}`);
	return await requestGet(`/api/vulcan/product/translation/${simulatorId}/${locale}`);
}

/**
 * Get product from an editor
 * @returns Request response
 */
export async function getEditorProducts() {
	return await requestGet('/api/vulcan/product/list');
}

/**
 * Adds a new simulator to Vulcan
 * @param {*} product Simulator to add
 * @returns Request response
 */
export async function createSimulator(product) {
	return await requestPost('/api/vulcan/product', product);
}

/**
 * Updates a simulator informations in Vulcan
 * @param {*} productId Simulator id
 * @param {*} productData Simulator informations data
 * @returns Request response
 */
export async function editSimulator(productId, productData) {
	return await requestPut(`/api/vulcan/product/${productId}`, productData);
}

/**
 * Remove a simulator
 * * @param {*} id Product id
 * @returns Request response
 */
export async function removeSimulator(id) {
	return await requestDelete(`/api/vulcan/product/deactivate/${id}`);
}

/**
 * Updates the modules list in a simulator
 * @param {*} productId Simulator id
 * @param {*} modules Modules (json format)
 * @returns Request response
 */
export async function setModules(productId, modules) {
	return await requestPost(`/api/vulcan/product/${productId}/modules`, modules);
}

/**
 * Updates the sensors list in a simulator
 * @param {*} productId Simulator id
 * @param {*} sensors Sensors (form data)
 * @returns Request response
 */
export async function setSensors(productId, sensors) {
	return await requestPost(`/api/vulcan/product/${productId}/sensors`, sensors);
}

/**
 * Updates the simulation variables list in a simulator
 * @param {*} productId Simulator id
 * @param {*} simVars Simulation variables (json format)
 * @returns Request response
 */
export async function setSimulationVariables(productId, simVars) {
	return await requestPost(`/api/vulcan/product/${productId}/simvars`, simVars);
}

/**
 * Updates simulator translation
 * @param {string} ressourceName Ressource who will be translate
 * @param {object} locale The language choosen for the translation
 * @param {object} productRessourcesTranslation Object of the translations
 * @returns Request response
 */
export async function updateRessourceTranslation(ressourceName, locale, productRessourcesTranslation) {
	return await requestPut(`/api/vulcan/product/translation/${ressourceName}/${locale}/update`, productRessourcesTranslation);
}