import {
    SAVE_USER_INFORMATIONS_SUCCESS, SAVE_USER_INFORMATIONS_FAILURE,
    EXPEL_USER_FROM_ORGANIZATION_SUCCESS, EXPEL_USER_FROM_ORGANIZATION_FAILURE,
    USERS_SEARCH_SUCCESS, USERS_SEARCH_FAILURE
} from './UsersActions';

/**
 * Initial state
 */
const initialState = {
    organizationRoleList: [],
    userInformations: {}
};

const handlers = {

    // List of users successfully fetched
    [SAVE_USER_INFORMATIONS_SUCCESS]: (previousState, payload) => {
        return Object.assign({}, previousState, {
            userInformations: payload
        });
    },
    // List of users unsuccessfully fetched
    [SAVE_USER_INFORMATIONS_FAILURE]: (previousState, payload) => {
        return Object.assign({}, previousState, {
            errorCode: payload
        });
    },

    // User successfully expelled
    [EXPEL_USER_FROM_ORGANIZATION_SUCCESS]: (previousState, payload) => {
        return Object.assign({}, previousState, {
            usersList: payload
        });
    },
    // User unsuccessfully expelled
    [EXPEL_USER_FROM_ORGANIZATION_FAILURE]: (previousState, payload) => {
        return Object.assign({}, previousState, {
            errorCode: payload
        });
    },

    // User search successfully fetched
    [USERS_SEARCH_SUCCESS]: (previousState, payload) => {
        return Object.assign({}, previousState, {
            usersList: payload
        });
    },
    // User search unsuccessfully fetched
    [USERS_SEARCH_FAILURE]: (previousState, payload) => {
        return Object.assign({}, previousState, {
            errorCode: payload
        });
    }
};

export default function usersReducer(state = initialState, action) {
    let handler = handlers[action.type];
    if (!handler) return state;
    return { ...state, ...handler(state, action.payload) };
}

