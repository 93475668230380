import { GET_CLASSES_LIST_SUCCESS, GET_CLASSES_LIST_FAILURE, RESET_CLASSES_LIST } from './ClassesActions';

/**
 * Initial state
 */
const initialState = {
    classes: {}
};

const handlers = {

    [GET_CLASSES_LIST_SUCCESS]: (previousState, payload) => {
        return Object.assign({}, previousState, {
            classes: payload
        });
    },
    [GET_CLASSES_LIST_FAILURE]: (previousState, payload) => {
        return Object.assign({}, previousState, {
            errorCode: payload
        });
    },
    [RESET_CLASSES_LIST]: (previousState, payload) => {
        return Object.assign({}, previousState, {
            classes: payload
        });
    }
};

export default function classesReducer(state = initialState, action) {
    let handler = handlers[action.type];
    if (!handler) return state;
    return { ...state, ...handler(state, action.payload) };
}

