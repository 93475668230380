/**
 * Routes' names
 */

// Common
export const PAGINATION = '/:page(\\d+)';
// ------ Apps ------
export const APPS = '/apps';

// ------ Organizations ------
export const ORGANIZATIONS = '/organizations';
export const CREATE_ORGANIZATION = ORGANIZATIONS + '/create';
export const LIST_ORGANIZATION = ORGANIZATIONS + '/list';

export const ORGANIZATION_SETTINGS = '/organization/:id';

// ------ Dashboard ------
export const DASHBOARD = '/dashboard';
// Results
export const RESULTS = '/dashboard/results';
export const USER_RESULTS = RESULTS + '/users/:id';
export const USER_RESULTS_EXERCISE_DETAILS = RESULTS + '/users/:userId/details/:resultId';

// Exercises
export const EXERCISES = '/dashboard/exercises';
export const EXERCISES_DEFAULT_VISUALIZE = EXERCISES + '/:id';
export const EXERCISE_EDIT = EXERCISES + '/:id/edit';

// Learning Paths
export const LEARNING_PATHS = DASHBOARD + '/learning_paths';
export const LEARNING_PATH_CREATE = LEARNING_PATHS + '/create';
export const LEARNING_PATH_EDIT = LEARNING_PATHS + '/:id/edit';
export const LEARNING_PATH_DEFAULT_VISUALIZE = LEARNING_PATHS + '/:id';

// - Users -
export const USERS_PROFILE = '/profile/:id';
export const USERS = DASHBOARD + '/users';

// - Classes -
export const CLASS = '/dashboard/classes';
export const CLASS_CREATE = CLASS + '/create';
export const CLASS_EDIT = CLASS + '/:id/edit';

// - Licenses -
export const LICENSES = '/dashboard/licenses';

// ------ Editor ------
export const EDITOR = '/editor';

// - Simulators -
export const SIMULATORS = EDITOR + '/simulators';
export const SIMULATORS_CREATE = SIMULATORS + '/create';

// Creation/Edition
export const SIMULATORS_EDIT = SIMULATORS + '/:id';
export const SIMULATORS_CREATE_INTRO = SIMULATORS_CREATE + '/intro';
export const SIMULATORS_CRU_INFO = '/information';
export const SIMULATORS_CRU_VERSIONS = '/versions';
export const SIMULATORS_CRU_MODULES = '/modules';
export const SIMULATORS_CRU_SENSORS = '/sensors';
export const SIMULATORS_CRU_SIM_VAR = '/simvar';
export const SIMULATORS_CRU_OVERVIEW = '/overview';

// Translation
export const SIMULATORS_I18N = SIMULATORS + '/:id/locales';
export const SIMULATORS_I18N_SIMULATOR = '/simulator';
export const SIMULATORS_I18N_MODULE = '/module';
export const SIMULATORS_I18N_SENSOR = '/sensor';
export const SIMULATORS_I18N_VARIABLE = '/variable';
export const SIMULATORS_I18N_LEARNING_PATH = '/learning_path';
export const SIMULATORS_I18N_EXERCISE = '/exercise';


// - Exercises -
export const EDITOR_EXERCISES = EDITOR + '/exercises';
export const EDITOR_CREATE_EXERCISE = '/editor/exercises/create';
export const EDITOR_EDIT_EXERCISE = '/editor/exercises/:id/edit';

// Learning Paths
export const EDITOR_LEARNING_PATHS = EDITOR + '/learning_paths';
export const EDITOR_CREATE_LEARNING_PATH = EDITOR_LEARNING_PATHS + '/create';
export const EDITOR_EDIT_LEARNING_PATH = EDITOR_LEARNING_PATHS + '/:id/edit';

// Client creation
export const EDITOR_CLIENT = EDITOR + '/client';

// Licenses
export const EDITOR_LICENSES = EDITOR + '/licenses';
export const EDITOR_CREATE_LICENSES = EDITOR_LICENSES + '/create';

// ------ Distributor ------
export const DISTRIBUTOR = '/distributor';

export const DISTRIBUTOR_LICENSES = '/distributor/licenses';

// ------ Store ------
export const STORE = '/store';

// Plugins
export const STORE_PLUGINS = STORE + '/plugins';
export const STORE_CATEGORY = STORE + '/categories/:category';
export const STORE_LIBRARY = STORE + '/library';
export const STORE_PLUGIN_DETAILS = STORE_PLUGINS + '/:id';

// Breadcrumbs name map (fil d'ariane)
export const breadcrumbNameMap = {
	// ------ Organizations ------
	[ORGANIZATIONS]: 'common.organizations',

	// ------ Apps ------
	[APPS]: 'apps',

	// ------ Dashboard ------
	[DASHBOARD]: 'dashboard',

	[RESULTS]: 'common.results',

	[EXERCISES]: 'common.exercises',
	[EXERCISE_EDIT]: 'common.editing',

	[LEARNING_PATHS]: 'common.learning_paths',
	[LEARNING_PATH_CREATE]: 'common.create',
	[LEARNING_PATH_EDIT]: 'common.editing',

	[USERS]: 'dashboard.users',
	[USERS_PROFILE]: 'common.profile',

	[CLASS]: 'dashboard.classes',
	[CLASS_CREATE]: 'common.create',
	[CLASS_EDIT]: 'common.editing',

	[LICENSES]: 'common.licenses',

	[ORGANIZATION_SETTINGS]: 'common.organization',

	// ------ Editor ------
	[EDITOR]: 'editor',

	[SIMULATORS]: 'common.simulators',
	[SIMULATORS_CREATE]: 'simulators.create',

	[EDITOR_EXERCISES]: 'common.exercises',
	[EDITOR_CREATE_EXERCISE]: 'common.create',

	[EDITOR_LEARNING_PATHS]: 'common.learning_paths',
	[EDITOR_CREATE_LEARNING_PATH]: 'common.create',

	[EDITOR_CLIENT]: 'editor.add_client',
	[EDITOR_LICENSES]: 'licenses.create',

	// ------ Distributor ------
	[DISTRIBUTOR]: 'distributor',
	[DISTRIBUTOR_LICENSES]: 'licenses.list',

	// ------ Store ------
	[STORE]: 'store',
	[STORE_PLUGINS]: '',
	[STORE_CATEGORY]: '',
	[STORE_LIBRARY]: 'store.plugins',
	[STORE_PLUGIN_DETAILS]: ''

};
