import { createStore, combineReducers, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { routerMiddleware, routerReducer } from 'react-router-redux';
import { createBrowserHistory } from 'history';

import globalReducer from './common/requests/global/GlobalReducer';
import organizationReducer from './common/requests/organization/OrganizationsReducer';
import simulatorsReducer from './common/requests/simulators/SimulatorsReducer';
import exercisesReducer from './common/requests/exercises/ExercisesReducer';
import classesReducer from './components/dashboard/classes/requests/ClassesReducer';
import usersReducer from './components/dashboard/users/requests/UsersReducer';
import notificationsReducer from './common/notifications/requests/NotificationsReducer';
import sensorsReducer from './components/editor/exercises/requests/SensorsReducer';
import variablesReducer from './components/editor/exercises/requests/VariablesReducer';
import pluginsReducer from './common/requests/plugins/PluginsReducer';

// Create a history of your choosing (we're using a browser history in this case)
const history = createBrowserHistory();

// Build the middleware for intercepting and dispatching navigation actions
const middleware = routerMiddleware(history);

// The reducers (initial state)
const reducers = combineReducers({
	router: routerReducer,
	global: globalReducer,
	organizations: organizationReducer,
	simulators: simulatorsReducer,
	exercises: exercisesReducer,
	sensors: sensorsReducer,
	variables: variablesReducer,
	classes: classesReducer,
	users: usersReducer,
	plugins: pluginsReducer,
	notifications: notificationsReducer
});

// Here the store using redux-thunk to be able to call async function in an action
const store = composeWithDevTools(applyMiddleware(thunk, middleware))(createStore)(reducers);

export { store, history };