import { Ability, AbilityBuilder } from '@casl/ability';
import { keycloak } from '@/auth/keycloak';
import { store } from '@/store';
import { isNullOrUndefined } from '@/utils';

// Roles
const SUPER_ADMINISTRATOR = 'SUPERADMINISTRATOR';
const EDITOR = 'EDITOR';
const DISTRIBUTOR = 'DISTRIBUTOR';
const ADMINISTRATOR = 'ADMINISTRATOR';
const TRAINER = 'TRAINER';
const LEARNER = 'LEARNER';

// Subjects
export const ABILITY_APPLICATIONS = 'APPLICATIONS';
export const ABILITY_APP_EDITOR = 'APP EDITOR';
export const ABILITY_APP_DISTRIBUTOR = 'APP DISTRIBUTOR';

export const ABILITY_MENU_PEDAGOGY = 'MENU PEDAGOGY';
export const ABILITY_MENU_RESULTS = 'MENU RESULTS';
export const ABILITY_MENU_EXERCISES = 'MENU EXERCISES';
export const ABILITY_MENU_LEARNING_PATHS = 'MENU LEARNING PATHS';

export const ABILITY_MENU_ADMINISTRATION = 'MENU ADMINISTRATION';
export const ABILITY_MENU_USERS = 'MENU USERS';
export const ABILITY_MENU_USERS_ROLES = 'ROLES';
export const ABILITY_MENU_USERS_EXTRA_ROLES = 'EXTRA ROLES';
export const ABILITY_MENU_CLASSES = 'MENU CLASSES';
export const ABILITY_MENU_LICENSES = 'MENU LICENCES';
export const ABILITY_MENU_LICENSES_EDIT = 'EDIT LICENSE';
export const ABILITY_MENU_ORGANIZATION_SETTINGS = 'ACCESS ORGANIZATION SETTINGS';

export const ABILITY_CREATE_ORGANIZATION = 'CREATE AN ORGANIZATION';
export const ABILITY_LIST_ORGANIZATION = 'CREATE AN ORGANIZATION';

export const ABILITY_RESULTS_LIST = 'RESULTS LIST';
export const ABILITY_USER_RESULTS = 'USER RESULTS';
export const ABILITY_CUSTOM_EXERCISE = 'CUSTOM EXERCISE';
export const ABILITY_DEFAULT_EXERCISE = 'DEFAULT EXERCISE';

/**
 * Defines or update user ability
 * @param {*} role User role given
 * @param {*} ability User ability to update, if not given, ability will be defined
 * @returns Ability if no ability to update was given
 */
export default function defineAbility(role, ability) {

	const { can, cannot, rules } = new AbilityBuilder();

	// Access to redux store to get user id
	const state = store.getState();
	let userId;

	// Role
	role = isNullOrUndefined(role) ? '' : role;

	// Authenticated to Keycloak ?
	if (keycloak.authenticated) {

		// Super admin role take over
		if (keycloak.hasRealmRole(SUPER_ADMINISTRATOR)) role = SUPER_ADMINISTRATOR;

		// Get user id from redux store
		userId = state.users?.userInformations?.id;

	}

	switch (role) {
		case SUPER_ADMINISTRATOR:
			// console.log('SUPER ADMIN rights');
			can('manage', 'all');
			break;

		case EDITOR:
			// console.log('EDITOR rights');
			can('read', ABILITY_APP_EDITOR);
			can('read', ABILITY_MENU_LICENSES_EDIT);
		// falls through (priviledges of administrator and trainer)

		case DISTRIBUTOR:
			// console.log('DISTRIBUTOR rights');
			can('read', ABILITY_APPLICATIONS);
			can('read', ABILITY_APP_DISTRIBUTOR);
		// falls through (priviledges of administrator and trainer)

		case ADMINISTRATOR:
			// console.log('ADMIN rights');
			can('read', ABILITY_MENU_LICENSES);
			can('read', ABILITY_MENU_USERS_ROLES);
			can('read', ABILITY_MENU_ORGANIZATION_SETTINGS);
		// falls through (priviledges of trainer)

		case TRAINER:
			// console.log('TRAINER rights');
			can('read', ABILITY_MENU_PEDAGOGY);
			can('read', ABILITY_MENU_RESULTS);
			can('read', ABILITY_MENU_EXERCISES);
			can('read', ABILITY_MENU_LEARNING_PATHS);
			can('read', ABILITY_MENU_ADMINISTRATION);
			can('read', ABILITY_MENU_USERS);
			can('read', ABILITY_MENU_CLASSES);

			can('read', ABILITY_RESULTS_LIST);
			can('read', ABILITY_USER_RESULTS);
			can('read', ABILITY_CUSTOM_EXERCISE);

			can('update', 'UserProfile', { id: userId }); // Peut modifier son propre profil
			can('update', 'UserProfile', { role: 'LEARNER' }); // Peut modifier les profils des apprenants
			cannot('update', 'UserProfile', { role: 'TRAINER' }); // Ne peut pas modifier les profils d'autres formateurs
			cannot('update', 'UserProfile', { role: 'ADMIN' }); // Ne peut pas modifier les profils des administrateurs

			break;

		case LEARNER:
			// console.log('LEARNER rights', userId);
			can('read', ABILITY_MENU_RESULTS);
			if (userId) can('read', ABILITY_USER_RESULTS, { id: userId });
			break;
	}

	if (ability) ability.update(rules);
	else return new Ability(rules);
}