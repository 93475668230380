import { isNullOrUndefined, requestGet, requestPost, requestPut } from '@/utils';

export const SAVE_EXERCISE_ID_FOR_A_RESULT_SUCCESS = '@@exercises/SAVE_EXERCISE_ID_FOR_A_RESULT_SUCCESS';


/**
 * Gets exercises from a module by its tag
 * @param {*} moduleTag Module tag
 * @returns Request response
 */
export async function getExercisesListByModule(moduleTag) {
	return await requestGet(`/api/vulcan/module/${moduleTag}/exercise/list`);
}

/**
 * Gets the authorized exercises by module's id
 * @param {*} moduleId Module's id
 * @returns Request response
 */
export async function getAuthorizedExercises(moduleId) {
	return await requestGet(`/api/vulcan/exercise/authorizations/${moduleId}`);
}

/**
 * Gets the exercise informations wihtout its reference
 * @param {*} exerciseId Exericse's id
 * @returns Request response
 */
export async function getBasicExercise(exerciseId) {
	return await requestGet(`/api/vulcan/exercise/basic/${exerciseId}`);
}

/**
 * Gets the exercise informations with its reference
 * @param {*} exerciseId Exericse's id
 * @returns Request response
 */
export async function getCompleteExercise(exerciseId) {
	return await requestGet(`/api/vulcan/exercise/complete/${exerciseId}`);
}

/**
 * Get the exercise
 * @param {*} exerciseId Exercise's id
 */
export async function getExerciseById(exerciseId) {
	return await requestGet(`/api/vulcan/exercise/${exerciseId}`);
}

export async function getExerciseByIdForAnEditor(exerciseId) {
	return await requestGet(`/api/vulcan/exercise/${exerciseId}/default`);
}

/**
 * Gets all translation for an exercise
 * @param {string} exerciseId Exercise id
 * @param {string} locale The locale choosen to get the translation
 * @returns Request response
 */
export async function getExerciseTranslation(exerciseId, locale) {
	return await requestGet(`/api/vulcan/exercise/${exerciseId}/${locale}`);
}

/**
 * Gets exercises matching the input
 * @param {*} input The input given by the user
 * @param {*} productId Product's id
 * @param {*} isFilteredByExercises Need to be filter with the authorized exercises
 * @returns Request response
 */
export async function searchExercises(input, productId, isFilteredByExercises) {
	return await requestGet(`/api/vulcan/exercise/search/${productId}/${input}/${isFilteredByExercises}`);
}

/**
 * Check for the custom exercise during the creation if the name already exist
 * @param {string} productId The exercise who will be created
 * @param {string} exerciseName Object who contains the exercise's name
 */
export async function isExerciseNameAlreadyExist(productId, exerciseName) {
	return await requestPost(`/api/vulcan/exercise/${productId}/exist`, exerciseName);
}

/**
 * Create a default exercise ONLY available to an editor
 * @param {*} exercicse The exercise who will be created
 */
export async function createDefaultExercise(exercicse) {
	return await requestPost('/api/vulcan/exercise/default', exercicse);
}

/**
 * Create an custom exercise
 * @param {*} exercicse The exercise who will be created
 */
export async function createCustomExercise(exercicse) {
	return await requestPost('/api/vulcan/exercise/custom', exercicse);
}

/**
 * Bound list of exercises to an organization once a license is transfered to it
 * @param {Array<object>} authorizedExercises Object that contains the list of exercises and the organization id
 * @param {number} licensesCount Number of licences
 */
export async function handleAuthorizedExercises(authorizedExercises, licensesCount) {
	if (isNullOrUndefined(licensesCount)) return await requestPost('/api/vulcan/exercise/authorizations', authorizedExercises);
	else return await requestPost(`/api/vulcan/exercise/authorizations/${licensesCount}`, authorizedExercises);
}

/**
 * Bound an exercise who has just been created to an organization
 * @param {Array<object>} organizationLicensesInformation List of object with organization id and the licenses' number
 * @param {string} exerciseId Exercise's id
 */
export async function setAuthorizedForNewExercise(organizationLicensesInformation, exerciseId) {
	return await requestPost(`/api/vulcan/exercise/authorizations/${exerciseId}/new`, organizationLicensesInformation);
}

/**
 * Update a default exercise ONLY available to an editor
 * @param {*} exerciseId The exercise's id
 * @param {*} exerciseUpdated The exercise updated
 */
export async function updateDefaultExercise(exerciseId, exerciseUpdated) {
	return await requestPut(`/api/vulcan/exercise/default/${exerciseId}`, exerciseUpdated);
}

/**
 * Update a custom exercise
 * @param {*} exerciseId The exercise's id
 * @param {*} exerciseUpdated The exercise updated
 */
export async function updateCustomExercise(exerciseId, exerciseUpdated) {
	return await requestPut(`/api/vulcan/exercise/custom/${exerciseId}`, exerciseUpdated);
}

/**
 * Delete a default exercise ONLY available to an editor
 * @param {*} exerciseListId Exercises' id list
 */
export async function deleteDefaultExercise(exerciseListId) {
	return await requestPut('/api/vulcan/exercise/default/list', exerciseListId);
}

/**
 * Delete a custom exercise
 * @param {*} exerciseListId Exercises' id list
 */
export async function deleteCustomExercise(exerciseListId) {
	return await requestPut('/api/vulcan/exercise/custom/list', exerciseListId);
}

/**
 * Save exercise id for a result
 * @param {*} exerciseId Exercise id
 */
export function saveExerciseIdForAResult(exerciseId) {
	return dispatch => {
		dispatch({
			type: SAVE_EXERCISE_ID_FOR_A_RESULT_SUCCESS,
			payload: exerciseId
		});
	};
}