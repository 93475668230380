import { SAVE_CURRENT_ACTIVE_PATH } from '../navigation/NavigationActions';

export const OPEN_SEARCH_MODAL = '@@global/OPEN_SEARCH_MODAL';

/**
 * Initial state
 */
const initialState = {
    openSearchModal: false,
    activePath: null
};

const handlers = {

    [OPEN_SEARCH_MODAL]: (previousState, payload) => {
        return Object.assign({}, previousState, {
            openSearchModal: payload
        });
    },

    [SAVE_CURRENT_ACTIVE_PATH]: (previousState, payload) => {
        return Object.assign({}, previousState, {
            activePath: payload
        });
    }
};


export default function globalReducer(state = initialState, action) {
    let handler = handlers[action.type];
    if (!handler) return state;
    return { ...state, ...handler(state, action.payload) };
}