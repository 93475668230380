import { isNullOrUndefined, requestDelete, requestGet, requestPost, requestPut } from '@/utils';
import { SUPER_ADMINISTRATOR } from '@/utils/constants';

export const SAVE_USER_INFORMATIONS_SUCCESS = '@@users/SAVE_USER_INFORMATIONS_SUCCESS';
export const SAVE_USER_INFORMATIONS_FAILURE = '@@users/SAVE_USER_INFORMATIONS_FAILURE';
export const EXPEL_USER_FROM_ORGANIZATION_SUCCESS = '@@users/EXPEL_USER_FROM_ORGANIZATION_SUCCESS';
export const INVITE_ANONYMOUS_USER_SUCCESS = '@@users/INVITE_ANONYMOUS_USER_SUCCESS';
export const INVITE_ANONYMOUS_USER_FAILURE = '@@users/INVITE_ANONYMOUS_USER_FAILURE';
export const EXPEL_USER_FROM_ORGANIZATION_FAILURE = '@@users/EXPEL_USER_FROM_ORGANIZATION_FAILURE';
export const USERS_SEARCH_SUCCESS = '@@users/USERS_SEARCH_SUCCESS';
export const USERS_SEARCH_FAILURE = '@@users/USERS_SEARCH_FAILURE';

/**
 * Get the user informations
 * @param {string} userId User's id
 */
export async function getUserInformations(userId) {
    return await requestGet(`/api/vulcan/user/profile/${userId}`)
        .then(async (response) => {
            const newProfile = response.data;

            if (!isNullOrUndefined(newProfile.attributes)) {
                // Property attributes is an object with different properties (gender, locale...), we want those properties directly in the profile so we extract them
                for (const [key, value] of Object.entries(newProfile.attributes)) {
                    newProfile[key] = value[0];
                }
            }

            // We extract all properties from 'attributes', we don't need it anymore
            delete newProfile.attributes;

            newProfile.birthdate = !isNullOrUndefined(newProfile.birthdate) ?
                (parseInt(newProfile.birthdate) !== 0 ?
                    parseInt(newProfile.birthdate) : null)
                : null;

            // Get user's avatar
            let avatarUrl;
            try {
                avatarUrl = await requestGet(`/api/vulcan/user/${newProfile.id}/logo`);
                newProfile.avatarUrl = avatarUrl.data;
            } catch (error) {
                console.log('Issue to fetch user avatar' + error);
            }

            return newProfile;
        });
}

/**
 * Save the user's in redux store
 * @param {*} keycloak Instance of Keycloak
 */
export function saveUserInformations(keycloak) {

    const userId = keycloak.subject;

    return async dispatch => {
        try {
            const newProfile = await getUserInformations(userId);
            // User is super admin
            if (keycloak.hasRealmRole(SUPER_ADMINISTRATOR)) newProfile.isSuperAdmin = true;
            else newProfile.isSuperAdmin = false;

            dispatch({
                type: SAVE_USER_INFORMATIONS_SUCCESS,
                payload: newProfile
            });
            return newProfile;
        } catch (error) {
            dispatch({
                type: SAVE_USER_INFORMATIONS_FAILURE,
                payload: error
            });
        }
    };
}

/**
 * Gets information about a user
 * @param {*} userId User id
 * @returns Request response
 */
export async function getUserById(userId) {
    return await requestGet(`/api/vulcan/user/complete/${userId}`);
}

/**
 * Get the user's avatar
 * @param {*} userId User's id
 * @returns Request response
 */
export async function getUserAvatar(userId) {
    return await requestGet(`/api/vulcan/user/${userId}/logo`);
}

/**
 * Fetch the organization with all users
 * @param {*} first First element
 * @param {*} count Number of elements requested
 * @param {*} order Order type (e.g firstname, lastname...)
 * @param {*} direction Ascending or Descending
 * @returns List of users
 */
export async function getUsersList(first, count, order, direction) {
    return await requestGet(`/api/vulcan/organization/users/${first}/${count}/${order}/${direction}`);
}

/**
 * Invite one or more user to the current organization
 * @param {*} invitationDto Object who contains a list of users, a list of classId and a role
 */
export async function inviteUserWithEmail(invitationDto) {
    return await requestPost('/api/vulcan/user/invite', invitationDto);
}

/**
 * Invite one or more user anonymously to the current organization
 * @param {*} invitationDto Object who contains a list of users, a list of classId and a role
 */
export async function inviteUserAnonymously(invitationDto) {
    return await requestPost('/api/vulcan/user/invite/anonymous', invitationDto);
}

/**
 * Invite users with a CSV file
 * @param {*} invitationDto Object who contains a CSV file, a list of classId and a role
 * @returns Request response
 */
export async function inviteUserWithCsvFile(invitationDto) {
    return await requestPost('/api/vulcan/user/invite/import', invitationDto);
}

/**
 * Expel a user from his organization
 * @param {*} userId Id of the user
 */
export async function expelUserFromOrganization(userId) {
    return await requestDelete(`/api/vulcan/organization/users/${userId}`);
}

/**
 * Search a user with the user input value
 * @param {*} value User input value
 */
export async function usersSearch(value) {
    return await requestGet(`/api/vulcan/organization/search/${value}`);
}

export async function updateUserProfile(userInformations) {
    return await requestPut('/api/vulcan/user/update', userInformations);

}

/**
 * Allows to change the user's password in Keycloak
 * @param {*} userId User's id
 * @param {*} newPassword Object who contains the new password
 */
export function changeUserPassword(userId, newPassword) {
    return requestPost(`/api/vulcan/user/${userId}/change-password`, newPassword)
        .then(response => {
            return response;
        })
        .catch(error => {
            return error;
        });
}