import { basicRequestPost, requestGet } from '../../../utils';

export const MESSAGES_LIST_FETCHED = '@@notifications/MESSAGES_LIST_FETCHED';
export const BLOCK_NOTIFICATION = '@@notifications/BLOCK_NOTIFICATION';

/**
 * Get notifications list for a specific organization
 */
export function getNotificationsList() {
	return dispatch => requestGet('/api/vulcan/message/list').then(response => {
		dispatch({
			type: MESSAGES_LIST_FETCHED,
			payload: response.data
		});
	});
}

export function blockNotification(id) {
	return dispatch => basicRequestPost(`/api/vulcan/message/block/${id}`).then(() => {
		dispatch({
			type: BLOCK_NOTIFICATION,
			payload: id
		});
	});
}