import React from 'react';
import axios from 'axios';
import { FormattedDate } from 'react-intl';
// import _ from 'lodash';

import { readAuthToken, readLocale, readOrganizationId } from './localStorage';

const vulcan_api_host = process.env.REACT_APP_VULCAN_API_URL;

// Utilities methods

/**
 * Checks if value is null or undefined
 * @param {*} value Value to be checked
 */
export function isNullOrUndefined(value) {
	return value === undefined || value === null;
}

/**
* Check if the value correspond to an email
* @param {*} email Email that needs to be verified
* @returns Boolean indicates if is an email or not
*/
export function isAnEmail(email) {
	let regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return regex.test(String(email).toLowerCase());
}

/**
 * Check if a guid is empty
 * @param {*} guid Guid
 * @returns Boolean
 */
export function isGuidEmpty(guid) {
	return guid === '00000000-0000-0000-0000-000000000000';
}

/**
 * Return the locale, otherwise return the default language (en-US).
 * @returns User locale
 */
export function getInterfaceLanguage() {
	const defaultLanguage = 'en-US';
	return !isNullOrUndefined(readLocale()) ? readLocale() : defaultLanguage;
}

/**
 * Returns the first element of a page depending on page size
 * @param {number} pageNumber The page number to be shown
 * @param {number} pageSize The number of elements shown
 * @return first element index
 */
export function getFirstElementIndexOfPage(pageNumber, pageSize) {
	return (parseInt(pageNumber) - 1) * parseInt(pageSize);
}

/**
 * Sort object properties/keys
 * @param {object} o Object
 * @returns Object with sorted properties
 */
export function sortObject(o) {
	return Object.keys(o).sort().reduce((r, k) => { r[k] = o[k]; return r; }, {});
}

/**
 * Checks if two objects are equal
 * @param {object} a Object A
 * @param {object} b Object B
 * @returns True if equal, False otherwise
 */
export function isEqual(a, b) {
	return a && b && JSON.stringify(sortObject(a)) === JSON.stringify(sortObject(b));
}

/**
 * Format message as we want it to be
 * @param {object} intl Locale key
 * @param {string} id Locale key
 * @param {*} key Key of the customize variable
 * @param {*} value Value of the customize variable
 * @returns Message
 */
export function translate(intl, id, key, value) {
	return intl.formatMessage({ id }, {
		[key]: value,
		br: <br />,
		b: (...chunks) => <b>{chunks}</b>,
		code: (...chunks) => <code>{chunks}</code>
	});
}

/**
 * Checks if an object is empty
 * @param {object} obj Object
 * @returns True if empty, False otherwise
 */
export function isObjectEmpty(obj) {
	return !isNullOrUndefined(obj)
		&& Object.keys(obj).length === 0
		&& Object.getPrototypeOf(obj) === Object.prototype;
}

/**
 * Get base 64 of the image
 * @param {*} img Image to be read
 * @param {*} callback Callback called after operation is done
 */
export function getBase64(img, callback) {
	const reader = new FileReader();
	reader.addEventListener('load', () => callback(reader.result));
	reader.readAsDataURL(img);
}

/**
 * Compare
 * @param {string} operator Operator choose to do the comparaise. Can be GREATER or LESS only
 * @param {*} compared The value that will be compared
 * @param {*} comparant The value compared to the reference value
 * @returns Result of the comparaison. Can be true or false according to the given operator
 */
export function isGreaterOrLess(operator, compared, comparant) {
	let result;
	switch (operator) {
		case 'GREATER': result = compared > comparant;
			break;
		case 'LESS': result = compared < comparant;
			break;
	}

	return result;
}

/**
 * Convert milliseconds into a date
 * @param {int} dateInMs Date in milliseconds
 * @param {bool} detailDate If true display the date with time otherwise only the date
 */
export function convertMsToDate(dateInMs, detailDate = false) {

	const formatDate = detailDate ?
		(<FormattedDate
			value={new Date(dateInMs)}
			year='numeric'
			month='2-digit'
			day='2-digit'
			hour='2-digit'
			minute='2-digit'
		/>)
		:
		(<FormattedDate
			value={new Date(dateInMs)}
			year='numeric'
			month='2-digit'
			day='2-digit'
		/>);

	return formatDate;
}

/* Hex to rgba
* @param {*} hex Hexadecimal color
* @param {*} opacity Opacity
*/
export function hexToRGBA(hex, opacity) {

	hex = hex.replace('#', '');

	const r = parseInt(hex.substring(0, 2), 16);
	const g = parseInt(hex.substring(2, 4), 16);
	const b = parseInt(hex.substring(4, 6), 16);

	return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')';
}

/**
 * Converts milliseconds to minutes and secondes (e.g 00:00)
 * @param {*} ms Milliseconds
 * @returns Minutes and seconds
 */
export function convertMsToMinutesAndSeconds(ms) {
	const minutes = Math.floor(ms / 60000);
	const seconds = Math.floor((ms % 60000) / 1000).toFixed(0);

	return `${minutes < 10 ? 0 : ''}${minutes}:${seconds < 10 ? 0 : ''}${seconds}`;
}

/**
 * Converts milliseconds to Days, hours, minutes, seconds
 * @param {*} milliseconds Total milliseconds to convert
 * @param {*} intl React intl
 * @param {*} noSeconds Set to true to hide seconds display
 * @returns Days, hours, minutes, seconds string format
 */
export function toDaysMinutesSeconds(milliseconds, intl, noSeconds = false) {
	const seconds = noSeconds ? Math.floor((milliseconds / 1000) % 60) : 0;
	const minutes = Math.floor((milliseconds / 1000 / 60) % 60);
	const hours = Math.floor((milliseconds / 1000 / 60 / 60) % 24);
	const days = Math.floor(milliseconds / 1000 / (3600 * 24));

	let timeResult = [];

	if (days > 0) {
		const daysStr = intl.formatMessage({ id: 'day' }, { days });
		timeResult.push(`${daysStr}`);
	}
	if (hours > 0) {
		const hoursStr = intl.formatMessage({ id: 'hour' }, { hours });
		timeResult.push(`${hoursStr}`);
	}
	if (minutes > 0) {
		const minutesStr = intl.formatMessage({ id: 'minute' }, { minutes });
		timeResult.push(`${minutesStr}`);
	}
	if (seconds > 0) {
		const secondsStr = intl.formatMessage({ id: 'second' }, { seconds });
		timeResult.push(`${secondsStr}`);
	}

	return timeResult.join(' ');
}

export function scrollToTop() {
	window.scrollTo({ top: 0, behavior: 'smooth' });
}

/**
 * Requests a specific plugin among organization's plugins
 * @param {string} pluginName Plugin's name
 * @param {array} organizationPlugins All organization's plugins
 * @returns {object} Requested plugin or null if its not found
 */
export function getPlugin(pluginName, organizationPlugins) {
	let requestedPlugin = null;

	if (isNullOrUndefined(organizationPlugins) && organizationPlugins.length === 0) {
		return requestedPlugin;
	}
	// Look through all the plugins
	organizationPlugins.forEach(pl => {
		// Only check the one's who are not default therefore the configurable
		if (!isNullOrUndefined(pl.isDefault) && !pl.isDefault) {
			const plName = pl.name.split('.').pop().toUpperCase();

			// Compares the plugin name with the component plugin name
			if (plName === pluginName) requestedPlugin = pl;
		}
	});

	return requestedPlugin;
}

/**
 * Check if a plugin is within the organization
 * @param {string} pluginName Plugin's name
 * @param {array} organizationPlugins All organization's plugins
 * @returns {bool} True if the plugin is used by the organization otherwise false
 */
export function isPluginAvailable(pluginName, organizationPlugins) {
	return !isNullOrUndefined(getPlugin(pluginName, organizationPlugins));
}

// #region -------------------HTTP REQUEST ---------------------------------

axios.defaults.headers = {
	'Cache-Control': 'max-age=0, must-revalidate, no-store, no-cache',
	'Pragma': 'no-cache',
	'Expires': '0'
};

/**
 * Simple and basic get request
 * @param {*} endpoint Request endpoint
 * @param {*} host Host URL
 * @param {*} params Parameters
 * @param {*} paramKey Key for the paramaters
 */
export function basicRequestGet(endpoint, host, params = null, paramKey = null) {

	let searchParams = new URLSearchParams();

	if (host == null) {
		host = vulcan_api_host;
	}
	if (params !== null && paramKey !== null) {
		params.forEach(param => {
			searchParams.append(paramKey, encodeURI(param));
		});

	}

	return axios.get(host + endpoint, { headers: getHeaders(), params: searchParams, withCredentials: true });
}

/**
 * Basic request post method
 * @param {*} endpoint Request endpoint
 * @param {*} data data
 * @param {*} config config
 * @param {*} host Host URL
 */
export function basicRequestPost(endpoint, data, config, host) {

	if (host == null) {
		host = vulcan_api_host;
	}

	if (config == null) {
		config = {};
	}

	return axios.post(host + endpoint, data, { headers: getHeaders(), ...config, withCredentials: true });
}

/**
 * Basic request put method
 * @param {*} endpoint Request endpoint
 * @param {*} data data
 * @param {*} host Host URL
 */
export function basicRequestPut(endpoint, data, host) {

	if (host == null) {
		host = vulcan_api_host;
	}

	return axios.put(host + endpoint, data, { headers: getHeaders(), withCredentials: true });
}

/**
 * Basic request delete method
 * @param {*} endpoint Request endpoint
 * @param {*} host Host URL
 */
export function basicRequestDelete(endpoint, data, host) {

	if (host == null) {
		host = vulcan_api_host;
	}

	if (!isNullOrUndefined(data)) {
		// Only this syntax below works to send data with a delete method
		const res = axios({
			method: 'DELETE',
			url: host + endpoint,
			data: data,
			headers: getHeaders()
		});
		return res;
	}

	return axios.delete(host + endpoint, { headers: getHeaders(), withCredentials: true });
}

export function requestGet(endpoint, host, params, paramKey) {

	return basicRequestGet(endpoint, host, params, paramKey);
}

export function requestPost(endpoint, data, config, host) {

	return basicRequestPost(endpoint, data, config, host);
}

export function requestPut(endpoint, data, host) {

	return basicRequestPut(endpoint, data, host);
}

export function requestDelete(endpoint, data, host) {

	return basicRequestDelete(endpoint, data, host);
}
// ---------------------------------------------------------------

/**
 * Return the headers of a fetch request
 * @return fetch request headers
 */
export function getHeaders() {

	// Default headers
	let headers = {};

	// Read token
	const token = readAuthToken();

	// Read orga id
	const orgaId = readOrganizationId();

	// Read locale
	let locale = readLocale();

	// // Set the locale in the headers
	headers = { 'Vulcan-Session': `{"currentLocale": "${locale}"}` };

	// If not null add token to headers
	if (token) {
		headers = Object.assign(headers, {
			'Authorization': `Bearer ${token}`
		}, headers);
	}

	// If not null, add orga id to headers
	if (orgaId) {
		headers = Object.assign(headers, {
			'Vulcan-Session': `{"currentOrganizationId": "${orgaId}", "currentLocale": "${locale}"}`
		}, headers);
	}

	return headers;
}
// #endregion