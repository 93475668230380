import { GET_PLUGINS_LIST_SUCCESS, GET_PLUGINS_LIST_FAILURE, GET_PLUGINS_LIST_IN_PROGRESS } from './PluginsAction';

/**
 * Initial state
 */
const initialState = {
    list: null,
    fetchingPlugins: false
};

const handlers = {

    [GET_PLUGINS_LIST_IN_PROGRESS]: (previousState) => {
        return Object.assign({}, previousState, {
            fetchingPlugins: true
        });
    },
    [GET_PLUGINS_LIST_SUCCESS]: (previousState, payload) => {
        return Object.assign({}, previousState, {
            list: payload,
            fetchingPlugins: false
        });
    },
    [GET_PLUGINS_LIST_FAILURE]: (previousState, payload) => {
        return Object.assign({}, previousState, {
            error: payload.errorCode,
            fetchingPlugins: false
        });
    }
};


export default function pluginsReducer(state = initialState, action) {
    let handler = handlers[action.type];
    if (!handler) return state;
    return { ...state, ...handler(state, action.payload) };
}